
import { defineComponent,reactive,toRefs,watch,ref} from "vue";
import {addUser} from "@/api/index";
import { Md5 } from 'ts-md5/dist/md5'
import { ElMessage } from 'element-plus';
interface LooseObject {
    [key: string]: any
}
interface deviceDataF{
    addUserFlag:boolean,
    addUserFrom:LooseObject,
    // rules:{
    //     addPhone:Array<any>,
    //     addName:Array<any>,
    //     pass:Array<any>,
    //     checkPass:Array<any>,
    //     addEmail:Array<any>
    // }
}
export default defineComponent({
    name:'AddUserTable',
    components:{
    },
    props:{
        dialongFlag:{
            type:Boolean,
        },
        chooseIp:{
            type:String,
        },
    },
    emits:['setConfigFlag','addSuccess'],
    setup(props,{emit}){
        const type3 = ref();
        const validatePass = (rule:any, value:any, callback:any) => {
            if (value === '') {
                callback(new Error('请输入密码！'))
            } else {
                callback()
            }
        }
        const validatePass2 = (rule:any, value:any, callback:any) => {
            if (value === '') {
                callback(new Error('请再次输入密码！'))
            } else if (value !== deviceData.addUserFrom.pass) {
                callback(new Error("两次输入得密码不一致！"))
            } else {
                callback()
            }
        }
        watch(() =>props.dialongFlag, async(nv, ov) => { 
            deviceData.addUserFlag = nv;
        });
        const deviceData:deviceDataF = reactive({
            addUserFlag:false,
            addUserFrom:{  },
            rules:{
                name:[
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                ],
                phone:[
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
                    {
                      pattern: /^1[34578]\d{9}$/,
                      message: '请输入正确的手机号码'
                    }
                ],
                pass: [
                    // { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur',required: true, }
                ],
                checkPass: [
                    // { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur',required: true, }
                ],
                addEmail:[
                    {
                      pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                      message: '请输入正确的邮箱地址'
                    }
                ],
            }
        });
        const data = toRefs(deviceData);
        const closedFun = ()=>{
            emit('setConfigFlag',false);
        }
        const addFun = async()=>{
            type3.value.validate(async (valid: any) => {
                if (valid) {
                    console.log('新增用户名');
                    // const { addName,addPhone,pass,addEmail,addAddress,addComment} = deviceData.addUserFrom;
                    // let params = {
                    //     addFaId: 0,
                    //     addName: addName,
                    //     addAccount: addPhone,
                    //     addPassword: pass,
                    //     addPhone: addPhone,
                    //     addEmail: addEmail,
                    //     addAddress:addAddress,
                    //     addComment: addComment,
                    //     // ip: props.chooseIp,
                    // };

                    deviceData.addUserFrom.password = Md5.hashStr(deviceData.addUserFrom.pass);

                    await addUser(deviceData.addUserFrom).then((res:any)=>{
                        if(res.code == 0){
                            emit('addSuccess',false);
                        }else{
                            if(res.errMsg){
                                ElMessage.error(res.errMsg);
                            }else{
                                ElMessage.error('添加用户失败');
                            }
                        }
                    });
                } else {
                    return false;
                }
            }); 
        }
        return {
          ...data,
          type3,
          closedFun,
          addFun
        }
    }
})
