
import {defineComponent,reactive,toRefs,watch,onMounted } from "vue";
// import { useStore } from './../store';
import { useStore } from "vuex";
import formatDate from './../util/formatDate';
import { Md5 } from 'ts-md5/dist/md5';
import ServerChoose from '@/components/ServerChoose.vue';
import AddUserTable from '@/components/dialong/AddUserTable.vue';
import {getAllUserList,updateUserInfo,deleteUser,isEditAuthByCode} from "@/api/index";
import { ElMessage } from 'element-plus';

interface LooseObject {
    [key: string]: any
}
interface deviceDataF{
    loading:boolean,
    // statusVal:string,  //0:正常 1:冻结 status
    statusOption:Array<any>,
    // customerName:string,
    customerData:Array<any>,  //
    total:number,
    pageSize:number,
    pageSizes:Array<number>,
    currentPage:number,
    chooseIp:string,
    dialongFlag:boolean,
    initFlag:boolean,
    titlePwd:string,
    initPwd:string,
    userId:number,
    statusDialong:boolean,
    statusParams:LooseObject,
    searchDataAny:any,
    searchTypeOption:any
}
export default defineComponent({
    name:'UserTable',
    components:{
        ServerChoose,
        AddUserTable
    },
    setup(){

        const store = useStore();
        console.log(store)
        const deviceData:deviceDataF = reactive({
            loading:true,
            // statusVal:'',  //0:正常 1:冻结 status
            statusOption:[
              {
                  value: '',
                  label: '全部',
              },
              {
                  value: '0',
                  label: '正常',
              },
              {
                  value: '1',
                  label: '冻结',
              }
            ],
            searchTypeOption:[
              {
                  value: 1,
                  label: '用户',
              },
              {
                  value: 2,
                  label: 'IMEI号',
              }
            ],

            // customerName:'',
            customerData:[
            ],  //
            total:0,
            pageSize:10,
            pageSizes:[10,30,50,100],
            currentPage:1,
            chooseIp:'',
            dialongFlag:false,
            initPwd:'',
            initFlag:false,
            titlePwd:'',
            userId:-1,
            statusDialong:false,
            statusParams:{},
            searchDataAny:{searchType:1}
        });
        const data = toRefs(deviceData);
        watch(()=> store.state.chooseIp,(n,o)=>{
            deviceData.chooseIp = n;
            deviceData.currentPage = 1;
            deviceData.loading = true;
            userList();
        });

        const formatDateF = formatDate.formatDateTime;

        const userList = async()=>{
            let params = new URLSearchParams();
           // let start:any = (deviceData.currentPage-1)*10;
            //params.append('ip',deviceData.chooseIp);
            // params.append('page',deviceData.currentPage as any);
            // params.append('length',(deviceData.pageSize as any));
            // params.append('draw',(deviceData.currentPage as any));
            // params.append('status',deviceData.statusVal);
            // params.append('keyWord',deviceData.customerName);
            
            deviceData.searchDataAny.page = deviceData.currentPage;
            deviceData.searchDataAny.length = deviceData.pageSize;

            await getAllUserList(deviceData.searchDataAny).then((res:any)=>{
                // deviceData.customerData = res.data;
                // deviceData.total = res.recordsTotal;
                // deviceData.currentPage = res.draw;
                if (res.code == 0) {
                    if(res.data!=null && res.data.list!=null){
                        deviceData.customerData = res.data.list
                        deviceData.total = res.data.total
                    }else{
                        deviceData.customerData = [];
                        deviceData.total = 0;
                        ElMessage.success('加载成功，数据为空！');
                    }
                    
                setTimeout(function(){
                    deviceData.loading = false
                },100);
                    // ElMessage.success('加载成功!')
                }
                else if(res.errMsg!=null){
                    ElMessage.error(res.errMsg);
                    deviceData.loading = false
                }else{
                    ElMessage.error("加载失败");
                    deviceData.loading = false
                }
            
            });
        }
        const statusChange = (e:string)=>{ 
            deviceData.currentPage = 1; //状态切换页码重新开始
            deviceData.loading = true;
            userList();
        }
        const selectCustomer = ()=>{
            deviceData.currentPage = 1;
            deviceData.loading = true;
            userList();
        }

        const addCustomer = ()=>{
            deviceData.dialongFlag = true;
        }
        const setConfigFlag = (flag:boolean)=>{
            deviceData.dialongFlag = flag;
        }
        const addSuccess = (flag:boolean)=>{  //新增用户成功
            deviceData.currentPage = 1;
            deviceData.dialongFlag = flag;
            deviceData.loading = true;
            userList();
        }
        const initPswFun = ((e:any)=>{
            deviceData.initFlag = true;
            deviceData.userId = e.userId;
            let name = e.name;
            if(name==null || name==''){
                name = e.account;
            }
            deviceData.titlePwd = `设置用户[ ${name} ]的初始化密码`;  
        });
        const statusFlagFun = (e:any)=>{
            let status = e.status == 0 ? 1 : 0;
            deviceData.statusParams = {
                id:e.userId,
                status:status,
            };
            deviceData.statusDialong = true;
        }
        const updateUserStatusFun = async()=>{
            await updateUserInfo(deviceData.statusParams).then((res:any)=>{
                if(res.code == 0){
                    deviceData.statusDialong = false;
                    ElMessage.success('状态修改成功!');
                    deviceData.loading = true;
                    userList();
                }else{
                    if(res.errMsg!=null){
                        ElMessage.success(res.errMsg);
                    }else{
                        ElMessage.success("状态修改失败");
                    }
                }
            });
        };
        const deleteCustomer = async(e:any)=>{
            let userId = e.id;
            let params = {
                userId:userId,
                ip:deviceData.chooseIp,
            }
            await deleteUser(params).then((res:any)=>{
                if(res.status == 0){
                    deviceData.loading = true;
                    ElMessage.success('删除成功!');
                    userList();
                }
            });
        };
        const handleSizeChange = (val:any) => {
            //设置当前页显示几条数据
            deviceData.pageSize = val;
            userList();
        }
        const handleCurrentChange = (val:any) => {
            //当前页码
            deviceData.currentPage = val;
            deviceData.loading = true;
            userList();
        }
        const pwdChange = async()=>{
            let params = {
                id:deviceData.userId,
                password:Md5.hashStr(deviceData.initPwd),
            }
            await updateUserInfo(params).then((res:any)=>{
                if(res.code == 0){
                    deviceData.initFlag = false;
                    deviceData.loading = true;
                    ElMessage.success('密码初始化成功!');
                    userList();
                }else{
                    if(res.errMsg!=null){
                        ElMessage.success(res.errMsg);
                    }else{
                        ElMessage.success("密码初始化失败");
                    }
                }
            });
        }
        onMounted(async()=>{
            // let params = {};
            // await getIpAddress(params).then((res:any)=>{
            //     if(res.code == 200){
            //         if(res.rows.length > 0){
            //             if(!store.state.chooseIp){
            //                 deviceData.chooseIp = res.rows[0].ip;
            //             }else{
            //                 deviceData.chooseIp = store.state.chooseIp;
            //             }
            //         }
            //     }
            // })
            userList();
        });
        return {
            ...data,
            formatDateF,
            statusChange,
            selectCustomer,
            addCustomer,
            setConfigFlag,
            addSuccess,
            initPswFun,
            statusFlagFun,
            updateUserStatusFun,
            deleteCustomer,
            handleSizeChange,
            handleCurrentChange,
            pwdChange,
            isEditAuthByCode
        }
    }
})
